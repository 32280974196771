<template>
  <div>
    <Main class="general-background">
      <a-row :gutter="25">
        <a-col :sm="24" :xs="24">
          <sdCards headless class="search-card">
            <a-row class="mobile-gap">
              <a-col :xs="24" :md="16">
                <label style="font-size: 15px; font-weight: bold; padding-bottom: 1rem;">
                  {{ t('generic.titles.search_cryptos') }}
                </label>
                <asset-by-ticker
                  v-model="isinTkr"
                  @blur="resetTicker"
                  tickerType="crypto"
                  :title="$t('search_cryptos.search_asset')"
                  @selectedTkrs="updateTkrs"
                  @selectedSearch="selectCryptos"
                  :loading="isLoading"
                  :auto-filter="false"
                >
                </asset-by-ticker>
              </a-col>
            </a-row>
          </sdCards>
          <basket-card ref="fundsTable" assetType="master_others_columns">
          </basket-card>
        </a-col>
      </a-row>
    </Main>
  </div>
</template>

<script setup>
import { Main } from "../styled";
import { ref, watch, onMounted } from "vue";

import AssetByTicker from "../../components/wiseral/searchAnalysis/AssetByTicker.vue";
import { useAuthStore } from "@/stores/AuthStore";
import { useCryptoStore } from "@/stores/CryptosStore";
import { useBasketStore } from "@/stores/BasketStore";
import BasketCard from "@/components/wiseral/basket/BasketCard.vue";
import { message } from "ant-design-vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

const { t } = useI18n();

const router = useRouter();
const basketStore = useBasketStore();
const authStore = useAuthStore();
const cryptoStore = useCryptoStore();
const isLoading = ref(false);
// const cryptos = cryptoStore.data;
const isinTkr = ref([]);

// const showGrid = computed(() => {
//   return cryptosList.value.length > 0 || basketStore.countAssets() > 0
// });

const updateTkrs = (e) => {
  authStore.logAction('add_asset');
  e.map((f) => {
    basketStore.addAssetById(e.type, f.id);
  });
};

const resetTicker = () => {
  isinTkr.value = cryptoStore.isin;
};

watch(
  () => isinTkr.value,
  () => {
    cryptoStore.isin = isinTkr.value.filter((e) => typeof e === "number");
  }
);

const selectCryptos = async () => {
  isLoading.value = true;
  try {
    const result = await cryptoStore.getCryptos(cryptoStore.isin);
    if (result && Array.isArray(result)) {
      await basketStore.addAsset(result);
      message.success(t("funds_printer.added_cryptos"));
    }
  } catch (err) {
    isLoading.value = false;
    console.log(err);
  } finally {
    isLoading.value = false;
  }
};

onMounted(async () => {
  const path = router.currentRoute.value.path;
  await basketStore.changeBasketUrl(path);
});
</script>
<style scoped lang="scss">
.mobile-gap {
  & > * {
    margin: 10px 0;
  }
}
.search-card {
  border: 1px solid #02B388;
  background-color: transparent;
  .ant-card-body {
    padding: 0 2rem !important;
  }
}
</style>

<style lang="scss">
.native-select.modal-select {
  .ant-select-selector {
    border: 1px #000 solid;
    padding: 0px 10px !important;
    margin: 0 !important;
    height: 45px;
  }
  .ant-select-arrow {
    margin-right: 10px;
  }
}
</style>
